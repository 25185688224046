import React from "react";
import { Slide } from "react-slideshow-image";
import image1 from "../images/plat.jpg";
import image2 from "../images/logo-devanture.jpg";
import image3 from "../images/jambon.jpg";
const Slideshow = () => {
  const responsiveSettings = [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 390,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ];

  const indicators = () => {
    return <div className="indicator"></div>;
  };

  return (
    <section id="slideshow" className="slideshow">
      <div className="container-fluid" data-aos="fade-up">
        <div className="section-header">
          <h2>Gallerie</h2>
          <p>
            Avant<span>-goût</span>
          </p>
        </div>
        <Slide
          slidesToScroll={2}
          slidesToShow={2}
          indicators={indicators}
          arrows={false}
          responsive={responsiveSettings}
        >
          <div className="each-slide-effect">
            <div
              style={{
                backgroundImage: `url(${image2})`,
              }}
            />
          </div>
          <div className="each-slide-effect">
            <div
              style={{
                backgroundImage: `url(${image1})`,
              }}
            />
          </div>
          <div className="each-slide-effect ">
            <div
              style={{
                backgroundImage: `url(${image3})`,
              }}
            />
          </div>
        </Slide>
      </div>
    </section>
  );
};

export default Slideshow;
