import React from "react";

const HeroTwo = () => {
  return (
    <>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec at auctor
      sem. Aliquam eget tortor convallis, sodales nisl gravida, consequat felis.
      Curabitur quis neque velit. Vivamus nec laoreet neque. Suspendisse
      potenti. Aliquam ex ante, tempor semper sollicitudin a, tempor id enim.
      Vestibulum euismod dictum lorem sit amet pharetra. Morbi rhoncus in metus
      vitae accumsan. Nam euismod tortor ipsum, a sagittis tellus pellentesque
      in. Vivamus a eleifend risus. Aliquam ut risus eu arcu venenatis iaculis.
      Vestibulum malesuada aliquam elit sodales porta. Donec tempor ante sit
      amet leo ullamcorper, ac ornare nunc laoreet. Aliquam blandit rutrum
      ipsum. Mauris eget lorem massa. Aenean at ipsum sit amet orci tincidunt
      bibendum. Cras vel metus odio. In blandit quam eu dui consectetur lacinia
      in nec augue. Nam interdum justo blandit erat viverra, quis pulvinar metus
      semper. Duis accumsan at mauris sit amet volutpat. Mauris tellus lacus,
      egestas id neque ac, faucibus pulvinar urna. Nullam orci dui, sagittis non
      eros ut, elementum consequat lacus. Sed at bibendum turpis. Cras libero
      augue, fermentum non tortor in, fringilla rutrum mauris. Ut molestie
      lectus vel orci rutrum semper. Fusce odio mi, fermentum eget mattis ut,
      sollicitudin in risus. Suspendisse bibendum diam vel nisi convallis
      feugiat. Aliquam sodales eros sed eleifend commodo. Sed vel ligula
      condimentum, consequat nisl placerat, mattis massa. Duis faucibus rutrum
      tellus. Integer a suscipit magna, nec aliquet lacus. Nulla ultrices quis
      nulla quis facilisis. Praesent consectetur euismod tortor non elementum.
      Maecenas tincidunt volutpat consequat. Morbi justo turpis, mollis a
      ultricies sit amet, dapibus imperdiet dolor. Vivamus laoreet tempus
      fermentum. Fusce id ipsum sagittis, vehicula quam id, finibus purus. In
      vel lectus sit amet velit lacinia vestibulum sed ut libero. Phasellus
      rutrum maximus nibh ut cursus. Cras est tortor, facilisis sed consectetur
      vitae, ultricies ac lacus. Maecenas non erat in nibh rutrum sodales. Orci
      varius natoque penatibus et magnis dis parturient montes, nascetur
      ridiculus mus. Praesent posuere nunc quis ex bibendum, vel pretium nisl
      vehicula. Nulla tincidunt rutrum odio, nec efficitur sem molestie
      condimentum. Nunc dapibus, enim nec tristique imperdiet, urna ex laoreet
      risus, a gravida orci justo id dolor. Duis mi orci, tempus in iaculis eu,
      efficitur fermentum massa. Praesent ultricies malesuada justo eu pulvinar.
      Sed ornare condimentum faucibus. Nam in est eu augue tempor convallis a a
      ligula. Phasellus efficitur, est non ultricies efficitur, erat tortor
      dignissim ex, eget eleifend risus nunc id lectus. Fusce tristique
      consequat nisl at viverra. Aliquam ut risus sollicitudin, finibus nunc
      non, vehicula augue. Vestibulum nec massa accumsan, bibendum ipsum vitae,
      imperdiet quam. Vestibulum sit amet consequat quam, sit amet malesuada
      est. Praesent vulputate tellus urna, vitae rhoncus magna laoreet ac.
      Aliquam metus arcu, tincidunt vitae risus sed, consequat condimentum
      turpis. Cras viverra ex sit amet molestie elementum. Vestibulum
      consectetur cursus facilisis. Nulla ac blandit nunc, a hendrerit odio.
      Vivamus non felis dolor. Curabitur eget purus sit amet dolor interdum
      congue ut nec felis. Nulla imperdiet, magna sed tempor fermentum, mi ipsum
      vulputate massa, nec cursus lectus arcu at ex. Nulla ut tempor tortor.
    </>
  );
};

export default HeroTwo;
