import React from "react";
import { StaticImage } from "gatsby-plugin-image";
const Exterieur = () => {
  return (
    <StaticImage
      src="../../images/exterieur.jpg"
      placeholder="blurred"
      alt="exterieur"
      quality="100"
      className="img-fluid"
    />
  );
};

export default Exterieur;
