import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Exterieur } from "../components/images";

const HeroOne = () => {
  return (
    <section id="hero" className="hero d-flex align-items-center section-bg">
      <Container>
        <Row className="justify-content-between gy-5">
          <Col
            lg={5}
            className="order-2 order-lg-1 d-flex flex-column justify-content-center align-items-center align-items-lg-start text-center text-lg-start"
          >
            <h2 data-aos="fade-up">Bar à tapas situé près de la mer</h2>
            <p data-aos="fade-up" data-aos-delay="100">
              Ambiance familiale, déco moderne et sympathique.
              <br />
              Repas sur place · Vente à emporter · Pas de livraison
            </p>
            <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
              <a href="tel:07 86 73 33 06" className="btn-book-a-table">
                Réservations : 07 86 73 33 06
              </a>
            </div>
          </Col>
          <Col lg={5} className="order-1 order-lg-2 text-center text-lg-start">
            <div data-aos="zoom-out" data-aos-delay="300">
              <Exterieur />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HeroOne;
