import React, { useState, useEffect } from "react";
import { Container, Navbar } from "react-bootstrap";
import { BsList } from "@react-icons/all-files/bs/BsList";
import { BsX } from "@react-icons/all-files/bs/BsX";
import { Link } from "gatsby";

const Header = () => {
  const [toggleNavOpen, setToggleNavOpen] = useState(false);

  const toggleNav = () => {
    setToggleNavOpen(!toggleNavOpen);
  };

  useEffect(() => {
    if (toggleNavOpen) {
      document.body.classList.add("mobile-nav-active");
    } else {
      document.body.classList.remove("mobile-nav-active");
    }
  });

  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <Container className="container d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center me-auto me-lg-0">
          <h1>
            TASCO <span>. île de ré</span>
          </h1>
        </a>

        <Navbar id="navbar">
          <ul>
            <li>
              <Link to="/" onClick={toggleNav}>
                Accueil
              </Link>
            </li>
            <li>
              <Link to="#about" onClick={toggleNav}>
                A propos
              </Link>
            </li>
            <li>
              <Link to="#slideshow" onClick={toggleNav}>
                Gallerie
              </Link>
            </li>
            <li>
              <Link to="#contact" onClick={toggleNav}>
                Contact
              </Link>
            </li>
          </ul>
        </Navbar>
        <BsList
          onClick={toggleNav}
          className={`mobile-nav-toggle mobile-nav-show ${
            toggleNavOpen ? "d-none" : ""
          }`}
        />
        <BsX
          onClick={toggleNav}
          className={`mobile-nav-toggle mobile-nav-hide ${
            toggleNavOpen ? "" : "d-none"
          }`}
        />
      </Container>
    </header>
  );
};

export default Header;
