import React from "react";
import Header from "./header";
import Footer from "./footer";
import { node } from "prop-types";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: node.isRequired,
};

export default Layout;
