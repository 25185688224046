import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsGeoAlt } from "@react-icons/all-files/bs/BsGeoAlt";
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt";
import { BsClock } from "@react-icons/all-files/bs/BsClock";

const Footer = () => {
  return (
    <footer id="footer" className="footer">
      <Container>
        <Row className="gy-3">
          <Col lg={4} md={6} className="d-flex">
            <BsGeoAlt className="icon" />
            <div>
              <h4>Adresse</h4>
              <p>
                28 avenue du Peu Ragot, <br />
                17670 La Couarde-sur-Mer
                <br />
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="footer-links d-flex">
            <FaMobileAlt className="icon" />
            <div>
              <h4>Réservations</h4>
              <p>
                <strong>Téléphone :</strong> 07 86 73 33 06
              </p>
            </div>
          </Col>
          <Col lg={4} md={6} className="footer-links d-flex">
            <BsClock className="icon" />
            <div>
              <h4>Horaires</h4>
              <p>
                <strong>Mardi :</strong> 11:30–15:00 / 18:30–00:00
                <br />
                <strong>Mercredi :</strong> 11:30–15:00 / 18:30–00:00
                <br />
                <strong>Jeudi :</strong> 11:30–15:00 / 18:30–00:00
                <br />
                <strong>Vendredi :</strong> 11:30–15:00 / 18:30–00:00
                <br />
                <strong>Samedi :</strong> 11:30–15:00 / 18:30–00:00
                <br />
                Dimanche : Fermé
                <br />
                Lundi : Fermé
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <div className="copyright">
          &copy;&nbsp;{new Date().getFullYear()},&nbsp;
          <strong>
            <span>Tasco - île de ré</span>
          </strong>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
