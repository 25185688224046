import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaCheckDouble } from "@react-icons/all-files/fa/FaCheckDouble";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { Plat } from "../components/images";

const About = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "interieur.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            quality: 100
          )
        }
      }
    }
  `);
  const pluginImage = getImage(image);
  return (
    <section id="about" className="about">
      <Container data-aos="fade-up">
        <div className="section-header">
          <h2>A propos</h2>
          <p>
            Notre <span>cuisine</span>
          </p>
        </div>

        <Row className="gy-4">
          <Col lg={7}>
            <p className="fst-italic">
              Le Tasco, un charmant restaurant de tapas à deux pas de la plage
              qui vous invite à une expérience culinaire envoûtante et
              dépaysante.
            </p>
            <BgImage
              className="position-relative about-img"
              data-aos="fade-up"
              data-aos-delay="150"
              image={pluginImage}
            >
              <div className="call-us position-absolute">
                <h4>Réservations</h4>
                <p>07 86 73 33 06</p>
              </div>
            </BgImage>
          </Col>
          <Col
            lg={5}
            className="d-flex align-items-end"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
                Une symphonie de saveurs méditerranéennes.
              </p>
              <ul>
                <li>
                  <FaCheckDouble /> Des classiques portugais traditionnels.
                </li>
                <li>
                  <FaCheckDouble /> Des Pesticos portugais.
                </li>
                <li>
                  <FaCheckDouble /> Vaste sélection de vins.
                </li>
              </ul>

              <div className="position-relative mt-4">
                <Plat />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
