import React from "react";
import { StaticImage } from "gatsby-plugin-image";
const Plat = () => {
  return (
    <StaticImage
      src="../../images/plat.jpg"
      placeholder="blurred"
      alt="plat"
      quality="100"
      className="img-fluid"
    />
  );
};

export default Plat;
