import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsMap } from "@react-icons/all-files/bs/BsMap";
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt";
import { BsClock } from "@react-icons/all-files/bs/BsClock";

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <Container data-aos="fade-up">
        <div className="section-header">
          <h2>Contact</h2>
          <p>
            Nous <span>contacter</span>
          </p>
        </div>

        <Row className="gy-4">
          <Col md={4}>
            <div className="info-item d-flex align-items-center">
              <div className="icon flex-shrink-0">
                <BsMap />
              </div>
              <div>
                <h3>Notre Adresse</h3>
                <p>
                  28 avenue du Peu Rago
                  <br />
                  17670 La Couarde-sur-Mer
                </p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="info-item d-flex align-items-center">
              <div className="icon flex-shrink-0">
                <FaMobileAlt />
              </div>
              <div>
                <h3>Notre Téléphone</h3>
                <p>07 86 73 33 06</p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className="info-item  d-flex align-items-center">
              <div className="icon flex-shrink-0">
                <BsClock />
              </div>
              <div>
                <h3>Nos Horaires</h3>
                <div>
                  <strong>Mardi au Samedi :</strong>
                  <br />
                  11:30–15:00 / 18:30–00:00
                  <br />
                  <strong>Dimanche et Lundi :</strong> Fermé
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="mb-3 mt-5">
          <iframe
            style={{ border: "0", width: "100%", height: "350px" }}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4767.028240534943!2d-1.435075761141506!3d46.192074311848486!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4803f7d1388ac945%3A0xe710bdd7874541ae!2sTasco!5e0!3m2!1sfr!2sfr!4v1690577971199!5m2!1sfr!2sfr"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      </Container>
    </section>
  );
};

export default Contact;
